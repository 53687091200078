<template>
  <div>
    <div>
      <div v-if="estMobile == false">
        <SidebarMenu
          id="sidebarMenu"
          :collapsed="true"
          :hideToggle="true"
          :menu="menu"
          :rtl="true"
          @item-click="onItemClick"
        />
      </div>
      <div v-else>
        <MobileMenu
          @changementLangue="changerLangue"
          @changementTheme="changerTheme"
          @changementHref="onItemClickMobile"
        />
      </div>
    </div>
    <component
      :is="refreshCurrentView()"
      ref="composantActuel"
    />
  </div>
</template>

<script>
// Components
import { SidebarMenu } from 'vue-sidebar-menu';
import { markRaw } from 'vue/dist/vue.esm-bundler.js';
import Home from '../../views/HomeView.vue';
import OnlineGenerators from '../../views/personalProjects/OnlineGeneratorsView.vue';
import FAANGPlusApiSimplifier from '../../views/personalProjects/FAANGPlusApiSimplifierView.vue';
import CSSP from '../workExperience/CSSP/CSSP.vue';
import Scores from '../workExperience/Scores.vue';
import Cegep from '../school/cegep/Cegep.vue';
import Highschool from '../school/highSchool/HighSchool.vue';
import MobileMenu from '../../components/composantsGlobal/MobileMenu.vue';

const routes = {
  '/': Home,
  '/PersonalProjects/OnlineGenerators': OnlineGenerators,
  '/PersonalProjects/FAANGPlusApiSimplifier': FAANGPlusApiSimplifier,
  '/WorkExperience/CSSP': CSSP,
  '/WorkExperience/Scores': Scores,
  '/AcademicBackground/Cegep': Cegep,
  '/AcademicBackground/Highschool': Highschool,
};

const separator = {
  template: '<hr style="border-color: lightgray; margin: 20px;">',
};

export default {
  name: 'SidebarOptions',
  components: {
    SidebarMenu,
    MobileMenu,
  },
  data() {
    return {
      menu: [
        {
          header: 'Menu principal',
          hiddenOnCollapse: true,
        },
        {
          href: '/',
          title: this.$t('sidebar.home', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-home',
        },
        {
          title: this.$t('sidebar.projects', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-laptop-code',
          child: [
            {
              title: this.$t('sidebar.mainProjects.webProjects', {}, { locale: this.$i18n.locale }),
              icon: 'fa-brands fa-html5',
              child: [
                {
                  title: this.$t('sidebar.mainProjects.portfolio', {}, { locale: this.$i18n.locale }),
                  icon: 'fa fa-user',
                },
                {
                  href: '/PersonalProjects/OnlineGenerators',
                  title: this.$t('sidebar.OG', {}, { locale: this.$i18n.locale }),
                  icon: 'fa fa-lock'
                },
                {
                  href: '/PersonalProjects/FAANGPlusApiSimplifier',
                  title: this.$t('sidebar.FAANGPlusApiSimplifier', {}, { locale: this.$i18n.locale }),
                  icon: 'fa fa-book'
                },
                {
                  component: markRaw(separator),
                },
              ],
            },
          ]
        },
        {
          title: this.$t('sidebar.workExperience', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-briefcase',
          child: [
            {
              href: '/WorkExperience/CSSP',
              title: this.$t('sidebar.cssp', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-school',
            },
            {
              href: '/WorkExperience/Scores',
              title: this.$t('sidebar.scores', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-utensils',
            },
          ]
        },
        {
          title: this.$t('sidebar.academicBackground', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-graduation-cap',
          child: [
            {
              href: '/AcademicBackground/Cegep',
              title: this.$t('sidebar.college', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-school-flag',
            },
            {
              href: '/AcademicBackground/Highschool',
              title: this.$t('sidebar.highSchool', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-bus',
            },
          ]
        },
        {
          component: markRaw(separator),
        },
        {
          title: this.$t('sidebar.contact.header', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-user',
          child: [
            {
              title: this.$t('sidebar.contact.linkedIn', {}, { locale: this.$i18n.locale }),
              icon: 'fa-brands fa-linkedin',
              contact: 'linkedIn'
            },
            {
              title: this.$t('sidebar.contact.github', {}, { locale: this.$i18n.locale }),
              icon: 'fa-brands fa-github',
              contact: 'github'
            },
            {
              title: this.$t('sidebar.contact.mail', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-envelope',
              contact: 'mail'
            }
          ]
        },
        {
          title: this.$t('sidebar.options', {}, { locale: this.$i18n.locale }),
          icon: 'fa fa-gear',
          child: [
            {
              title: this.$t('sidebar.language', {}, { locale: this.$i18n.locale }),
              icon: 'fa fa-globe',
              child: [
                {
                  title: 'Français',
                  abbreviation: 'fr'
                },
                {
                  title: 'English',
                  abbreviation: 'en'
                }
              ]
            },
          ]
        },
      ],
      currentPath: window.location.hash,
      urlChoisi: '',
      href: '',
      theme: '',
      estMobile: false,
      oldPath: '',
      audio: null,
      timer: '',
      firstLoop: true,
      musicStopped: false,
      musics: [],
      currentTrack: 0,
      numberOfMusics: 0,
      idAncienInterval: -1,
      dureePopup: 5000,
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash;
    });
    this.stylesSidebar();

    // Gestion de la sidebar selon l'appareil utilisé
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
      this.dureePopup = 2000; // Pour pas prendre la moitié de l'écran trop longtemps
    }
  },
  methods: {
    onItemClickMobile(item) {
      this.onItemClick(new Event('click'), item);
    },
    onItemClick(event, item) {
      // Affectation différente en desktop VS mobile
      if (!this.estMobile) {
        this.href = item.href;
      } else {
        this.href = item;
      }

      // Changement de langue
      if (item.abbreviation !== undefined) {
        this.changerLangue(item.abbreviation);
      }

      // Changement de thèmes
      if (item.theme !== undefined) {
        this.changerTheme(item.theme);
      }

      // Gestion de contact
      if (item.contact !== undefined) {
        this.gestionContact(item.contact);
      }

      // Changement du component
      switch (item.href) {
        case '/':
          this.$refs.composantActuel.is = 'Home';
          break;
        case '/PersonalProjects/OnlineGenerators':
          this.$refs.composantActuel.is = 'OnlineGenerators';
          break;
        case '/PersonalProjects/FAANGPlusApiSimplifier':
          this.$refs.composantActuel.is = 'FAANGPlusApiSimplifier';
          break;
        case '/WorkExperience/CSSP':
          this.$refs.composantActuel.is = 'CSSP';
          break;
        case '/WorkExperience/Scores':
          this.$refs.composantActuel.is = 'Scores';
          break;
        case '/AcademicBackground/Cegep':
          this.$refs.composantActuel.is = 'Cegep';
          break;
        case '/AcademicBackground/Highschool':
          this.$refs.composantActuel.is = 'Highschool';
          break;
        default:
          this.$refs.composantActuel.is = 'Home';
          break;
      }

      // Permet de faire un refresh dans le :is pour changer de page
      this.refreshCurrentView();
    },
    refreshCurrentView() {
      if (this.href !== undefined) {
        this.$router.push({ path: this.href })
      }
      this.currentPath = this.href;
      if (this.currentPath === undefined) {
        return routes[this.oldPath || '/'];
      }
      this.oldPath = this.currentPath;
      return routes[this.currentPath || '/'];
    },
    stylesSidebar() {
      var sidebar = document.getElementById('sidebarMenu');
      // todo switch
      switch (this.theme) {
        case 'light':
        default:
          sidebar.classList.add('sidebarLightTheme');
          break;
      }
    },
    changerLangue(abbreviation) {
      this.$i18n.locale = abbreviation;
      localStorage.setItem('currentLang', this.$i18n.locale);
      window.location.reload();
    },
    changerTheme(theme) {
      localStorage.setItem('currentTheme', theme);
      window.location.reload();
    },
    gestionContact(contact) {
      switch (contact) {
        case 'linkedIn':
          window.open('https://www.linkedin.com/in/anthony-boileau-7211b7225/', '_blank').focus();
          break;
        case 'github':
          window.open('https://github.com/AnthonyBoileau', '_blank').focus();
          break;
        case 'mail':
          var mail = document.createElement('a');
          mail.href = 'mailto:anthonyboileau@hotmail.com';
          mail.click();
          break;
        default: // Rien faire en cas d'erreur
          break;
      }
    },
  }
}
</script>

<style scoped>
.sidebarLightTheme {
  background-color: #a9a9ab;
}
</style>