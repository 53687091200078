<template>
  <div class="marginBottomPourFooter">
    <Project
      :titre="title"
      :fonctionnalites="fonctionnalites"
      :lienProjetOuExe="'https://www.onlinegenerators.ca/'"
      :texteLien="texte"
      :description="description"
    />
    <div>
      <h2
        class="headers"
        :class="gestionThemes(texteType)"
      >
        {{ $t("globalHeaders.websiteDemonstration", {}, { locale: $i18n.locale }) }}
      </h2>
      <div style="display:flex; margin:0; justify-content:center; margin-top:-20px">
        <video
          style="margin-bottom: 10px"
          class="videos"
          autoplay
          loop
          muted
        >
          <source
            src="../../../assets/videos/OnlineGenerators/OG_LightTheme.mp4"
            type="video/mp4"
          >
          {{ $t("videoTagError", {}, { locale: $i18n.locale }) }}
        </video>
      </div>
      <div
        class="fullContent"
        :class="gestionThemes(borderType)"
      >
        <h2
          style="padding-top:18px"
          :class="gestionThemes(texteType)"
        >
          {{ $t("globalHeaders.listOfTechnologies", {}, { locale: $i18n.locale }) }}
        </h2>
        <div style="display:flex;margin:0;justify-content:center">
          <div style="display:flex;margin:0;justify-content:center">
            <img
              src="../../../assets/img/Vuejs.png"
              alt="Vuejs.png"
              class="tailleImgs"
            >
            <img
              src="../../../assets/img/JS.png"
              alt="JS.png"
              class="tailleImgs"
            >
            <img
              src="../../../assets/img/HTML.png"
              alt="HTML.png"
              class="tailleImgs"
            >
            <img
              src="../../../assets/img/CSS.png"
              alt="CSS.png"
              class="tailleImgs"
            >
            <img
              src="../../../assets/img/GitHub.png"
              alt="Github.png"
              class="tailleImgs"
            >
            <img
              src="../../../assets/img/C-Sharp.png"
              alt="CSharp.png"
              class="tailleImgs"
            >
            <img
              src="../../../assets/img/Selenium.png"
              alt="Selenium.png"
              class="tailleImgs"
            >
          </div>
        </div>
        <img
          :class="estMobile == false ? 'languages' : 'langagesMobile'"
          style="height:auto;width:90%"
          src="../../../assets/img/OnlineGenerators/OnlineGenerators_Languages.png"
          alt="OG_Languages.png"
        >
      </div>
      <div>
        <h2
          class="headers"
          :class="gestionThemes(iconType)"
        >
          {{ $t("globalHeaders.seleniumTestsDemonstration", {}, { locale: $i18n.locale }) }}
        </h2>
        <div style="margin:0; justify-content:center; margin-top:-20px">
          <video
            class="videos"
            autoplay
            loop
            muted
            style="width:75%"
          >
            <source
              src="../../../assets/videos/OnlineGenerators/OG_TestsSelenium.mp4"
              type="video/mp4"
            >
            {{ $t("videoTagError", {}, { locale: $i18n.locale }) }}
          </video>
          <div>
            <img
              src="../../../assets/img/OnlineGenerators/OnlineGenerators_Kanban.png"
              alt="Kanban.png"
              style="width:77%;height:auto"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Project from '../../composantsGlobal/Project.vue';
import UtilitiesLightTheme from '../../../utilities/UtilitiesLightTheme';

export default {
  name: 'OnlineGenerators',
  components: {
    Project
  },
  data() {
    return {
      title: '',
      fonctionnalites: [],
      texte: '',
      description: '',
      theme: '',
      borderType: '',
      iconType: '',
      texteType: '',
      estMobile: false,
    }
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.gestionInternationalisation();
    this.borderType = 'border';
    this.iconType = 'icon';
    this.texteType = 'texte';
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionInternationalisation() {
      this.title = this.$t('onlineGenerators.title', {}, { locale: this.$i18n.locale });
      this.texte = this.$t('lienVersProjetWebTexte', {}, { locale: this.$i18n.locale });
      this.description = this.$t('onlineGenerators.description', {}, { locale: this.$i18n.locale });
      this.fonctionnalites.push(this.$t('onlineGenerators.func1', {}, { locale: this.$i18n.locale }));
    },
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'icon':
          switch (this.theme) {
            case 'light': return 'iconColorLightTheme';
            default: return 'iconColorLightTheme'
          }
        case 'border':
          switch (this.theme) {
            case 'light': return 'projectBorderStylesLightTheme';
            default: return 'projectBorderStylesLightTheme'
          }
        case 'texte':
          switch (this.theme) {
            case 'light': return 'lightThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        default:
          // Thème invalide, donc l'alerte blanche est utilisée par défaut
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error',
            this.$t('errors.styleError', {}, { locale: this.$i18n.locale }), this.$t('errors.styleErrorDesc', {}, { locale: this.$i18n.locale }));
          break;
      }
    }
  },
};
</script>

<style scoped>
.videos {
  width: 75%;
  border-radius: 12px;
  margin-top: 20px;
}

.spacer {
  width: 1.5%;
}

.languages {
  width: 30%;
  height: auto;
  margin-top: 20px;
  margin-bottom:10px;
}

.languagesMobile {
  height: auto;
  margin-top: 20px;
  margin-bottom:10px;
}

.tailleImgs {
  width: 8%;
  height: auto;
  margin-right: 10px;
}

.headers {
  margin-top: 35px;
}
</style>