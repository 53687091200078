<template>
  <FAANGPlusApiSimplifier />
</template>

<script>
import FAANGPlusApiSimplifier from '../../components/personalProjects/FAANGPlusApiSimplifier/FAANGPlusApiSimplifier.vue';

export default {
  name: 'FAANGPlusApiSimplifierView',
  components: {
    FAANGPlusApiSimplifier
  }
}
</script>