import { createWebHistory, createRouter } from 'vue-router';
import HomeView from '../views/HomeView';
import OnlineGeneratorsView from '../views/personalProjects/OnlineGeneratorsView';
import CSSPView from '../views/workExperience/CSSPView';
import ScoresView from '../views/workExperience/ScoresView';
import CegepView from '../views/school/CegepView';
import HighSchoolView from '../views/school/HighSchoolView';
import FAANGPlusApiSimplifierView from '../views/personalProjects/FAANGPlusApiSimplifierView';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
    },
    {
        path: '/PersonalProjects/OnlineGenerators',
        name: 'OnlineGenerators',
        component: OnlineGeneratorsView,
    },
    {
        path: '/PersonalProjects/FAANGPlusApiSimplifier',
        name: 'FAANGPlusApiSimplifier',
        component: FAANGPlusApiSimplifierView,
    },
    {
        path: '/WorkExperience/CSSP',
        name: 'CSSP',
        component: CSSPView,
    },
    {
        path: '/WorkExperience/Scores',
        name: 'Scores',
        component: ScoresView,
    },
    {
        path: '/AcademicBackground/Cegep',
        name: 'Cegep',
        component: CegepView,
    },
    {
        path: '/AcademicBackground/Highschool',
        name: 'Highschool',
        component: HighSchoolView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;