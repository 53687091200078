<template>
  <div style="margin-bottom: 40px">
    <Presentation
      :mainTitle="mainTitle"
      :translatedTitle="translatedTitle"
      :description="description"
      :startDate="'2017'"
      :endDate="'2022'"
      :tasks="tasks"
      :lienSiteWeb="'https://www.cegepsth.qc.ca/'"
    />
    <img
      src="../../../assets/img/Cegep/Cegep.png"
      alt="Cegep.png"
      :class="estMobile == false ? 'tailleImageCegep' : 'tailleImageCegepMobile'"
    >
    <div>
      <h2
        class="headers"
        :class="gestionThemes(texteType)"
      >
        {{ $t("cegep.someProjects", {}, { locale: $i18n.locale }) }}
      </h2>
      <div
        style="margin:auto;justify-content:center;width:75%"
        :class="estMobile == false ? 'flex' : ''"
      >
        <div style="margin:auto">
          <h2 :class="gestionThemes(texteType)">
            ePicerie
          </h2>
          <p
            :class="gestionThemes(texteType)"
            style="text-align:justify;margin-right:10px"
          >
            {{ $t("cegep.epicerie", {}, { locale: $i18n.locale }) }}
          </p>
        </div>
        <img
          src="../../../assets/img/Cegep/ePicerie.png"
          alt="ePicerie.png"
          :class="estMobile == false ? 'tailleImage' : 'tailleImageMobile'"
        >
      </div>
      <div
        style="margin:auto;justify-content:center;width:75%;margin-top:20px"
        :class="estMobile == false ? 'flex' : ''"
      >
        <div style="margin:auto">
          <h2 :class="gestionThemes(texteType)">
            BlocLand
          </h2>
          <p
            :class="gestionThemes(texteType)"
            style="text-align:justify;margin-right:10px"
          >
            {{ $t("cegep.blocland", {}, { locale: $i18n.locale }) }}
          </p>
        </div>
        <img
          src="../../../assets/img/Cegep/BlocLand.png"
          alt="ePicerie.png"
          :class="estMobile == false ? 'tailleImage' : 'tailleImageMobile'"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Presentation from '../../composantsGlobal/Presentation.vue';
import UtilitiesLightTheme from '../../../utilities/UtilitiesLightTheme';

export default {
  name: 'CegepComponent',
  components: {
    Presentation
  },
  data() {
    return {
      mainTitle: '',
      description: '',
      tasks: [],
      translatedTitle: '',
      texteType: '',
      estMobile: false,
      theme: '',
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.gestionInternationalisation();
    this.texteType = 'texte';
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionInternationalisation() {
      this.mainTitle = this.$t('cegep.mainTitle', {}, { locale: this.$i18n.locale });
      this.translatedTitle = this.$t('cegep.translatedTitle', {}, { locale: this.$i18n.locale });
      this.description = this.$t('cegep.description', {}, { locale: this.$i18n.locale });
      this.tasks.push(this.$t('cegep.task1', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('cegep.task2', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('cegep.task3', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('cegep.task4', {}, { locale: this.$i18n.locale }));
    },
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'texte':
          switch (this.theme) {
            case 'light': return 'lightThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        default:
          // Thème invalide, donc l'alerte blanche est utilisée par défaut
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error',
            this.$t('errors.styleError', {}, { locale: this.$i18n.locale }), this.$t('errors.styleErrorDesc', {}, { locale: this.$i18n.locale }));
          break;
      }
    }
  },
}
</script>

<style scoped>
.tailleImageCegep {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tailleImageCegepMobile {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tailleImageCegep, .tailleImageCegepMobile {
  border: 1px solid black;
}

.tailleImage {
  width: 50%;
  height: auto;
}

.tailleImageMobile {
  height: auto;
}

.flex {
  display:flex;
}
</style>