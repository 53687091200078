<template>
  <div>
    <SidebarOptions />
  </div>
</template>

<script>
// import Header from './components/composantsGlobal/header/Header.vue';
import SidebarOptions from './components/composantsGlobal/SidebarOptions.vue';
import './assets/styles/SidebarMenu.css';
import './assets/styles/Portfolio.css';
import UtilitiesLightTheme from '../src/utilities/UtilitiesLightTheme';

export default {
  name: 'App',
  components: {
    // Header,
    SidebarOptions,
  },
  data() {
    return {
      theme: '',
    };
  },
  created() {
    try {
      this.$i18n.locale = localStorage.getItem('currentLang');
      this.theme = localStorage.getItem('currentTheme');

      // Si le localstorage retourne null, mettre le thème light par défaut pour pas briser le site
      if (this.theme === null) {
        localStorage.setItem('currentTheme', 'light');
        this.theme = 'light';
      }

      // Si le localstorage retourne une valeur vide
      // Permet de garder le code des thèmes sans pouvoir les sélectionner
      if (this.theme === '' || this.theme !== 'light') {
        localStorage.setItem('currentTheme', 'light');
        this.theme = 'light';
      }
    } catch (e) {
      this.notifierErreurLocalStorage();
    }
  },
  mounted() {
    var htmlElement = document.querySelector('html');
    if (this.theme === null || this.theme === 'light') {
      htmlElement.classList.add('lightTheme');
    }
  },
  methods: {
    notifierErreurLocalStorage() {
      switch (this.theme) {
        case 'light':
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error', this.$t('errors.lackOfLocalStorage', {}, { locale: this.$i18n.locale }), '');
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.lightTheme {
  background-color: white;
}

/* .v-sidebar-menu.vsm_expanded .vsm--link_level-1.vsm--link_open {
    background-color: grey;
    color: #fff
} */

/*  class="background" :style="{'background-image': 'url(' + require('./assets/img/CodingBackground.jpg') + ')'}" */
/* .background {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-size:100% 100%;
  background-repeat: no-repeat;
  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
} */
</style>
