<template>
  <div class="marginBottomPourFooter">
    <div class="container">
      <img
        src="../assets/img/ProgrammingBackground.png"
        alt="background.png"
        style="height:auto;width:100%;opacity:0.8"
        :class="estMobile == false ? 'marginImageDesktop' : 'marginImageMobile'"
      >
      <div class="centered">
        <p
          class="texteDesktop"
          :class="estMobile == false ? 'texteNom' : 'texteNomMobile'"
        >
          Anthony Boileau
        </p>
        <p
          class="texteDesktop"
          :class="estMobile == false ? 'texteTitre' : 'texteTitreMobile'"
        >
          {{ $t("home.developer", {}, { locale: $i18n.locale }) }}
        </p>
      </div>
    </div>
    <div
      class="title-upperline"
      :class="!estMobile ? 'marginLine' : ''"
    />
    <div :class="estMobile == false ? 'flexDesktop' : 'flexMobile'">
      <img
        src="../assets/img/mainPhoto.png"
        alt="mainPhoto.png"
        :class="estMobile == false ? 'taillePhoto' : 'taillePhotoMobile'"
      >
      <div>
        <section :style="estMobile == false ? 'margin-left:30px' : ''">
          <h3 :class="gestionThemes('texte')">
            {{ $t("home.hi", {}, { locale: $i18n.locale }) }}
          </h3>
          <p
            v-if="!estMobile"
            :class="gestionThemes('texte')"
          >
            {{ $t("home.history", {}, { locale: $i18n.locale }) }}
            <span>{{ $t("home.ownerOf", {}, { locale: $i18n.locale }) }}<a style="margin-left:5px" href="https://faangplusapisimplifier.com/">FAANGPlusApiSimplifier</a>, <a href="https://www.onlinegenerators.ca/">Online Generators</a></span>
          </p>
          <p
            v-else
            :class="gestionThemes('texte')"
          >
            {{ $t("home.historyMobile", {}, { locale: $i18n.locale }) }}
            <span>{{ $t("home.ownerOf", {}, { locale: $i18n.locale }) }}<a style="margin-left:5px" href="https://faangplusapisimplifier.com/">FAANGPlusApiSimplifier</a>, <a href="https://www.onlinegenerators.ca/">Online Generators</a></span>
          </p>
          <div v-if="!estMobile">
            <p :class="gestionThemes('texte')">
              <u>
                {{ $t("home.frequentlyUsed", {}, { locale: $i18n.locale }) }}
              </u>
            </p>
            <Carousel :imagesProp="images" />
          </div>
        </section>
      </div>
    </div>
    <div v-if="estMobile">
      <section>
        <div>
          <p :class="gestionThemes('texte')">
            <u>
              {{ $t("home.frequentlyUsed", {}, { locale: $i18n.locale }) }}
            </u>
          </p>
          <Carousel :imagesProp="images" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Carousel from './composantsGlobal/Carousel.vue';

export default {
  name: 'HomeComponent',
  components: {
    Carousel,
  },
  data() {
    return {
      theme: '',
      texteType: '',
      estMobile: false,
    };
  },
  computed: {
    images() {
      const imagesArray = [
        'Vuejs.png', 'HTML.png', 'JS.png', 'Azure.png', 'C-Sharp.png', 'DotNet.png', 'MSSQL.png', 'Vuetify.png', 'CSS.png', 'GitHub.png'
      ];
      return imagesArray;
    },
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.texteType = 'texte';
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionThemes(styleBalise) {
      switch (styleBalise) {
        case 'stroke':
          if (this.theme === 'light') { return 'strokeColorLightTheme'}
          break;
        case 'texte':
          switch (this.theme) {
            case 'light': return 'lightThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        default:
          // TODO: Mettre sweetAlert
          break;
      }
    }
  }
}
</script>

<style scoped>
svg {
  font: bold 20px Garamond, Garamond;
  width: 100%;
  height: 170px;
}

text {
  fill: none;
  stroke-width: 0.5px;
  stroke-linejoin:unset;
  animation: 2s pulsate infinite;
}

.taillePhoto {
  max-width: 370px;
  border-radius: 50px;
  height:auto;
  margin-left: 135px;
}

.taillePhotoMobile {
  max-width: 160px;
  border-radius: 50px;
  height:auto;
}

.title-upperline {
  font-size: 20px;
  border-top-style: solid;
  max-width: 95%;
  border-top-width: 2px;
  justify-content: center;
  color: lightgrey;
  margin-left: 2.5%;
  margin-top: 30px;
  margin-bottom: 30px;
}

section {
  margin: auto;
  max-width: 80%;
}

.tailleImg {
  max-width: 60px;
  height: auto;
  margin-right: 30px;
}

.azure {
  max-width: 120px;
}

/* Centrer le texte dans l'image */
.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.texteDesktop {
  font-weight: bold;
}

.texteNom {
  font-size: 60px;
}

.texteTitre {
  font-size: 20px;
  margin-top: -40px;
}

.marginImageDesktop {
  margin-left: -20px;
  margin-top: -20px;
}

.flexDesktop {
  display: flex;
}

.texteNomMobile {
  font-size: 20px;
}

.texteTitreMobile {
  font-size: 10px;
  margin-top: -18px;
}

.marginLine {
  margin-right: 85px;
}
</style>
