<template>
  <CSSP />
</template>

<script>
import CSSP from '../../components/workExperience/CSSP/CSSP.vue';

export default {
  name: 'CSSPView',
  components: {
    CSSP
  }
}
</script>

<style scoped>

</style>