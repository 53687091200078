<template>
  <OnlineGenerators />
</template>

<script>
import OnlineGenerators from '../../components/personalProjects/onlineGenerators/OnlineGenerators.vue';

export default {
    name: 'OnlineGeneratorsView',
    components: {
      OnlineGenerators,
    }
}
</script>

<style scoped>

</style>