import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueSweetalert2 from 'vue-sweetalert2';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    legacy: false,
    locale: 'en-US',
    messages: {
        'en-US': {
            lackOfLocalStorage: 'Internationalization might not work due to the lack of a local storage on your current browser',
            home: {
                developer: 'Web Developer / Software Developer',
                hi: 'Hello, welcome to my portfolio!',
                frequentlyUsed: 'List of technologies I use frequently',
                history: 'In 2018, I started my training in programming and in 2022, I started my career officially in the field of IT. I am passionate about software development and always aim to improve my skills over the years. I am a hardworking and meticulous person who enjoys overcoming challenges and working with a dynamic team. To learn more about me, please explore my projects, my work experience as well as my academic background using the right sidebar.',
                historyMobile: 'In 2018, I started my training in programming and in 2022, I started my career officially in the field of IT. I am passionate about software development and always aim to improve my skills over the years. I am a hardworking and meticulous person who enjoys overcoming challenges and working with a dynamic team. To learn more about me, please explore my projects, my work experience and my academic background using the menu at the top of the page.',
                ownerOf: 'Creator of the following sites:',
            },
            onlineGenerators: {
                title: 'Online Generators',
                func1: 'Eight types of generators (passwords, QR codes, phones, emails, and more)',
                description: 'Online Generators is the start of many accomplishments. This is the first website I deployed on the Internet and the first I designed in Vue.js to practice with this JavaScript Framework before joining the Patriots School Service Center (see Professional Experience section for more details on this job). This website is very simple (e.g. No database usage), because it was originally a practice site to test new technologies. Also, I wanted to keep it simple to test a real application deployment. This project was used as the basis for making the portfolio you are currently viewing.'
            },
            faangPlusApiSimplifier: {
                title: 'FAANGPlusApiSimplifier',
                func1: 'Simplifier of several APIs, including those of Facebook, Amazon, Apple, Netflix (unofficial), Google and more',
                description: 'Creating a NuGet package has always been an idea that interested me. My goal in creating this project is to be able to have reusable code in future projects (e.g. PayPal API implementation). However, I thought it would be interesting if the community could also use the methods created in their own projects, so I created FAANGPlusApiSimplifier. The FAANGPlusApiSimplifier website is more advanced than Online Generators. This is my first personal website that uses a database.'
            },
            lienVersProjetWebTexte: 'Visit the website!',
            errors: {
                videoTagError: 'Sorry, your browser does not support the video tag.',
                lackOfLocalStorage: 'Internationalization might not work due to the lack of a local storage on your current browser',
                styleError: 'Styling error',
                styleErrorDesc: 'Please contact me to report the error'
            },
            projectComponent: {
                interestingFeatures: 'Interesting features:'
            },
            presentationComponent: {
                tasks: 'Summary of the tasks I had to perform:',
            },
            globalHeaders: {
                websiteDemonstration: 'Website demonstration:',
                seleniumTestsDemonstration: 'Unit tests (automation made with Selenium) + Kanban board:',
                listOfTechnologies: 'List of technologies used:',
                websitePreview: 'Website preview:',
            },
            sidebar: {
                home: 'Home',
                projects: 'Personal projects',
                OG: 'Online Generators',
                FAANGPlusApiSimplifier: 'FAANGPlusApiSimplifier',
                undisclosed: 'Upcoming project',
                workExperience: 'Work experience',
                cssp: 'CSSP',
                scores: 'Scores Rotisserie',
                academicBackground: 'Academic background',
                college: 'Cegep',
                highSchool: 'High school',
                options: 'Options',
                language: 'Language',
                mainProjects: {
                    webProjects: 'Web projects',
                    portfolio: 'Portfolio (current site)'
                },
                contact: {
                    header: 'Contact',
                    linkedIn: 'LinkedIn',
                    github: 'GitHub',
                    mail: 'Email'
                }
            },
            cssp: {
                mainTitle: 'Centre de services scolaire des Patriotes (CSSP)',
                translatedTitle: 'Patriots School Service Center',
                frontendTasks: 'Take care of user interfaces (Vuejs, Vuetify, Axios, CSS, HTML, Eslint, Stylelint and more)',
                backendTasks: 'Take care of CRUD operations (Create, Read, Update, Delete) and use of MSSQL (Migrations, stored procedures)',
                other: 'Fix bugs and do xUnit tests in various applications',
                barely: 'Work a little bit with Power Apps',
                description: 'The Patriotes school service center is my current job. I work on websites. Without going into too much detail, the user interfaces of the websites are made with the Vue.js framework, the logic of the site is made with .NET and the C# language and the MSSQL databases are used. The sites are used a lot for human resources or finance.'
            },
            scores: {
                mainTitle: 'Rôtisserie Scores',
                translatedTitle: 'Scores Rotisseries',
                task1: 'Open the restaurant (clean the dining room and toilets)',
                task2: 'Dishwashing dishes',
                task3: 'Prepare chicken, skewers, coleslaw and more for cooking, etc.',
                task4: 'Cleaning kitchen floors and walls',
                description: 'My very first job was as a dishwasher at a Scores rotisserie. I am very grateful for this opportunity, because it made me discover the job market, but more mainly working with a big team. Some days I practiced well to work under pressure. In addition, I had to learn to respect time limits and always apply myself well in my tasks, because in a restaurant things go quickly with the number of customers and the final presentation counts. After 5 years of experience there, I finally left and found my first job in IT (see CSSP).'
            },
            secondaire: {
                mainTitle: 'École secondaire Casavant',
                translatedTitle: 'Casavant High School',
                mainTask: 'Get the high school diploma',
                goal: 'Try to look for an interesting career',
                goal2: 'Find my passions and have friends',
                progRelated: 'Related to programming:',
                demonstration: 'Demonstration of two mini projects that I had created:',
                description: 'It was at this school that I attended my high school education to obtain my high school diploma. During this time, I was enrolled in the computer course offered to students.',
                explicationScratch: 'The only thing related to programming that I had during computer science classes in high school was learning Scratch. While looking in an old USB key, I found 14 mini projects dating from 2012! Anyway, it was my very first introduction to programming.'
            },
            cegep: {
                mainTitle: 'Cégep de Saint-Hyacinthe',
                translatedTitle: 'Cegep of Saint-Hyacinthe',
                description: 'I did my computer training at Cégep de Saint-Hyacinthe. This took 5 years to complete. The first year, I was enrolled in a \'Tremplin DEC\' program which offers access to college studies for students who have not resolved their career choice. During my second year, I officially started my studies in computer science. During this year, I failed my courses in programming and mathematics. However, I did not give up and continued my studies in this field. Having stronger foundations, I passed all the courses on the first try in all subsequent years and officially graduated in the year 2022.',
                task1: 'Graduation in Programming',
                task2: 'Learn a multitude of programming languages and their concepts',
                task3: 'Learn good programming standards',
                task4: 'Learn how to properly document applications and much more',
                someProjects: 'Here are some interesting programs that we had to do during our studies:',
                epicerie: 'ePicerie is a transactional website developed (localhost only) in C# with ASP.Net Core, MVC architecture and an MSSQL database. It is possible to browse the catalog of articles and to add articles to its basket, to connect to the site using an account and to place orders to buy.',
                blocland: 'Small clone of Minecraft that we had to create in our 3D programming course. The OpenGL library and the C++ language were used for this project.'
            },
        },
        'fr': {
            lackOfLocalStorage: "L'internationalisation peut ne pas fonctionner en raison de l'absence de stockage local sur votre navigateur actuel",
            home: {
                developer: 'Développeur web / Développeur de logiciels',
                hi: 'Bonjour, bienvenue sur mon portfolio!',
                frequentlyUsed: 'Liste de technologies que j\'utilise fréquemment',
                history: 'En 2018, j\'ai débuté ma formation en programmation et en 2022, j\'ai débuté ma carrière officiellement dans le domaine de l\'informatique. Je suis passionné du développement de logiciels et je vise toujours à améliorer mes compétences au fil des années. Je suis une personne travaillante et minutieuse qui aime surmonter des défis et travailler avec une équipe dynamique. Pour en apprendre davantage sur moi, veuillez explorer mes projets, mon expérience de travail ainsi que mon parcours académique en utilisant la barre latérale droite.',
                historyMobile: 'En 2018, j\'ai débuté ma formation en programmation et en 2022, j\'ai débuté ma carrière officiellement dans le domaine de l\'informatique. Je suis passionné du développement de logiciels et je vise toujours à améliorer mes compétences au fil des années. Je suis une personne travaillante et minutieuse qui aime surmonter des défis et travailler avec une équipe dynamique. Pour en apprendre davantage sur moi, veuillez explorer mes projets, mon expérience de travail ainsi que mon parcours académique en utilisant le menu en haut de la page.',
                ownerOf: 'Créateur des sites suivants:',
            },
            onlineGenerators: {
                title: 'Online Generators',
                func1: 'Huit types de générateurs (mots de passe, codes QR, téléphones, e-mails, et plus)',
                description: 'Online Generators est le début de plusieurs accomplissements. C\'est le premier site web que j\'ai déployé sur Internet et le premier que j\'ai conçu en Vue.js pour me pratiquer avec ce Framework JavaScript avant de joindre le CSSP (voir section Expérience Professionnelle pour plus de détails sur cet emploi). Ce site web est très simple (ex: pas d\'utilisation de base de données), car c\'était originalement un site de pratique pour tester des nouvelles technologies. De plus, je voulais garder ça simple pour tester un vrai déploiement d’application. Ce projet a été utilisé comme base pour faire le portfolio que vous consultez actuellement.'
            },
            faangPlusApiSimplifier: {
                title: 'FAANGPlusApiSimplifier',
                func1: 'Simplificateur de plusieurs API, dont celles de Facebook, Amazon, Apple, Netflix (non officiel), Google et plus',
                description: 'Créer un paquet NuGet a toujours été une idée qui m\'intéressait. Mon but en créant ce projet est de pouvoir avoir du code réutilisable dans des futures projets (ex: Implémentation de l\'API de PayPal). Toutefois, je me suis dis que ce serait intéressant que la communauté puisse aussi utiliser les méthodes créées dans leurs propres projets, donc j\'ai créé FAANGPlusApiSimplifier. Le site web de FAANGPlusApiSimplifier est plus avancé que celui d\'Online Generators. C\'est mon premier site web personnel qui utilise une base de données.'
            },
            lienVersProjetWebTexte: 'Visitez le site web!',
            errors: {
                videoTagError: 'Désolé, votre navigateur ne prend pas en charge la balise vidéo.',
                lackOfLocalStorage: "L'internationalisation peut ne pas fonctionner en raison de l'absence de stockage local sur votre navigateur actuel",
                styleError: 'Erreur de style',
                styleErrorDesc: 'Veuillez me contacter pour reporter l\'erreur'
            },
            projectComponent: {
                interestingFeatures: 'Fonctionnalités intéressantes:'
            },
            presentationComponent: {
                tasks: 'Résumé des tâches que j\'ai dû effectuer:',
            },
            globalHeaders: {
                websiteDemonstration: 'Démonstration du site:',
                seleniumTestsDemonstration: 'Tests unitaires (automatisation faite avec Selenium) + table Kanban:',
                listOfTechnologies: 'Liste de technologies utilisées:',
                websitePreview: 'Aperçu du site web:',
            },
            sidebar: {
                home: 'Accueil',
                projects: 'Projets personnels',
                OG: 'Online Generators',
                FAANGPlusApiSimplifier: 'FAANGPlusApiSimplifier',
                undisclosed: 'Projet à venir',
                workExperience: 'Expérience professionnelle',
                cssp: 'CSSP',
                scores: 'Rôtisserie Scores',
                academicBackground: 'Parcours académique',
                college: 'Cégep',
                highSchool: 'Secondaire',
                options: 'Options',
                language: 'Langue',
                mainProjects: {
                    webProjects: 'Projets Web',
                    portfolio: 'Portfolio (site actuel)'
                },
                contact: {
                    header: 'Contact',
                    linkedIn: 'LinkedIn',
                    github: 'GitHub',
                    mail: 'Courriel'
                }
            },
            cssp: {
                mainTitle: 'Centre de services scolaire des Patriotes (CSSP)',
                translatedTitle: 'Centre de services scolaire des Patriotes (CSSP)',
                frontendTasks: 'S\'occuper des interfaces utilisateur (Vuejs, Vuetify, Axios, CSS, HTML, Eslint, Stylelint et plus)',
                backendTasks: 'S\'occuper des opérations CRUD (Create, Read, Update, Delete) et utilisation de MSSQL (Migrations, procédures stockées)',
                other: 'Corriger des bugs et faire des tests xUnit dans diverses applications',
                barely: 'Travailler légèrement avec Power Apps',
                description: 'Le centre de services scolaires des Patriotes est mon emploi actuel. Je travaille sur des sites web. Sans aller trop dans les détails, les interfaces utilisateurs des sites web sont faites avec le framework Vue.js, la logique du site est faite avec .NET et le langage C# et les bases de données MSSQL sont utilisées. Les sites conçus servent beaucoup aux ressources humaines ou aux finances.'
            },
            scores: {
                mainTitle: 'Rôtisserie Scores',
                translatedTitle: 'Scores Rotisseries',
                task1: 'Ouvrir le restaurant (nettoyer la salle à manger et les toilettes)',
                task2: 'Laver les assiettes à la plonge',
                task3: 'Préparer le poulet, les brochettes, salade de chou et plus pour la cuisine, etc',
                task4: 'Nettoyage des planchers et des murs de la cuisine',
                description: 'Mon tout premier emploi était être un plongeur à une rôtisserie Scores. Je suis très reconnaissant de cette opportunité, car celle-ci m\'a faite découvrir le marché du travail, mais plus principalement le travail avec une grosse équipe. Certaines journées m\'ont bien pratiqué à travailler sous la pression. De plus, j\'ai dû apprendre à respecter des limites de temps et toujours bien m\'appliquer dans mes tâches, car dans un restaurant ça va vite avec le nombre de clients et la présentation finale compte. Après 5 ans d\'expérience à cet endroit, j\'ai finalement quitté et trouvé mon premier emploi en informatique (voir CSSP).'
            },
            secondaire: {
                mainTitle: 'École secondaire Casavant',
                translatedTitle: 'Casavant High School',
                mainTask: 'Obtenir le diplôme d\'études secondaires',
                goal: 'Essayer de chercher une carrière intéressante',
                goal2: 'Trouver mes passions et avoir des amis',
                progRelated: 'Relié à la programmation:',
                demonstration: 'Démonstration de deux minis projets que j\'avais créé:',
                description: 'C\'est à cette école que j\'ai suivi mes études secondaires pour l\'obtention de mon diplôme d\'études secondaires. Pendant ce parcours, j\'étais inscrit dans le cours d\'informatique offert aux étudiants.',
                explicationScratch: 'La seule chose reliée à la programmation que j\'ai eu pendant les cours d\'informatique au secondaire est l\'apprentissage de Scratch. En cherchant dans une ancienne clé USB, j\'ai retrouvé 14 minis projets qui datent de 2012! Bref, c\'était ma toute première introduction à la programmation.'
            },
            cegep: {
                mainTitle: 'Cégep de Saint-Hyacinthe',
                translatedTitle: 'Cegep of Saint-Hyacinthe',
                description: 'J\'ai suivi ma formation en informatique au cégep de Saint-Hyacinthe. Celle-ci à durée 5 ans. La première année, j\'étais inscrit dans un programme \'Tremplin DEC\' qui propose l\'accès aux études collégiales aux étudiants qui n\'ont pas résolu leur choix de carrière. Lors de ma deuxième année, j\'ai officiellement débuté mes études en informatique. Durant cette année, j\'ai raté mes cours de programmation et de mathématiques. Toutefois, je n\'ai pas abandonné et j\'ai poursuivi mes études dans ce domaine. Ayant des bases plus fortes, j\'ai passé tous les cours du premier coup dans toutes les années subséquentes et j\'ai obtenu officiellement mon diplôme lors de l\'année 2022.',
                task1: 'Obtention du diplôme en programmation',
                task2: 'Apprendre une multitude de langages de programmation ainsi que leurs concepts',
                task3: 'Apprendre des bonnes normes de programmation',
                task4: 'Apprendre à bien documenter les applications et beaucoup plus',
                someProjects: 'Voici quelques programmes intéressants que nous avions dû faire lors de nos études:',
                epicerie: 'ePicerie est un site web transactionnel développé (localhost seulement) en C# avec ASP.Net Core, l\'architecture MVC et une base de données MSSQL. Il est possible de naviguer le catalogue d\'articles et d\'ajouter des articles à son panier, se connecter au site à l\'aide d\'un compte et à passer des commandes à acheter.',
                blocland: 'Petit clone de Minecraft que nous avions dû créer dans notre cours de programmation 3D. La librairie OpenGL et le langage C++ ont été utilisés pour ce projet.'
            },
        }
    }
});


const app = createApp(App)
app.use(VueSweetalert2)
app.use(router)
app.use(i18n)
app.mount('#app')
