<template>
  <div>
    <Presentation
      :mainTitle="mainTitle"
      :translatedTitle="translatedTitle"
      :description="description"
      :startDate="'2017'"
      :endDate="'2022'"
      :tasks="tasks"
    />
    <img
      src="../../assets/img/Scores/Scores.png"
      alt="Scores.png"
      :class="estMobile == false ? 'tailleImageScores' : 'tailleImageScoresMobile'"
    >
  </div>
</template>

<script>
import Presentation from '../../components/composantsGlobal/Presentation.vue';

export default {
  name: 'ScoresComponent',
  components: {
    Presentation
  },
  data() {
    return {
      mainTitle: '',
      description: '',
      tasks: [],
      translatedTitle: '',
      estMobile: false,
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.gestionInternationalisation();
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionInternationalisation() {
      this.mainTitle = this.$t('scores.mainTitle', {}, { locale: this.$i18n.locale });
      this.translatedTitle = this.$t('scores.translatedTitle', {}, { locale: this.$i18n.locale });
      this.description = this.$t('scores.description', {}, { locale: this.$i18n.locale });
      this.tasks.push(this.$t('scores.task1', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('scores.task2', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('scores.task3', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('scores.task4', {}, { locale: this.$i18n.locale }));
    },
  },
}
</script>

<style scoped>
.tailleImageScores {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tailleImageScoresMobile {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}
</style>