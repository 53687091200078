<template>
  <Cegep />
</template>

<script>
import Cegep from '../../components/school/cegep/Cegep.vue';

export default {
    name: 'CegepView',
    components: {
      Cegep
    }
}
</script>

<style scoped>

</style>