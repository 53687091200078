<template>
  <div class="marginBottomPourFooter">
    <Project
      :titre="title"
      :fonctionnalites="fonctionnalites"
      :lienProjetOuExe="'https://faangplusapisimplifier.com/'"
      :texteLien="texte"
      :description="description"
    />
    <div
      class="fullContent"
      :class="gestionThemes(borderType)"
    >
      <h2
        style="padding-top:18px"
        :class="gestionThemes(texteType)"
      >
        <u>{{ $t("globalHeaders.websitePreview", {}, { locale: $i18n.locale }) }}</u>
      </h2>
      <img
        src="../../../assets/img/FAANGPlusApiSimplifier/FAANGPlusAPISimplifier-Light-Portfolio.png"
        alt="FAANGPlusApiSimplifier-Light.png"
        :class="estMobile == false ? 'tailleImageFAANGPlus' : 'tailleImageFAANGPlusMobile'"
        style="margin-bottom:10px"
      >
    </div>
  </div>
</template>

<script>
import Project from '../../composantsGlobal/Project.vue';

export default {
  name: 'FAANGPlusApiSimplifier',
  components: {
    Project
  },
  data() {
    return {
      title: '',
      fonctionnalites: [],
      texte: '',
      description: '',
      theme: '',
      borderType: '',
      iconType: '',
      texteType: '',
      estMobile: false,
    }
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.gestionInternationalisation();
    this.borderType = 'border';
    this.iconType = 'icon';
    this.texteType = 'texte';
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionInternationalisation() {
      this.title = this.$t('faangPlusApiSimplifier.title', {}, { locale: this.$i18n.locale });
      this.texte = this.$t('lienVersProjetWebTexte', {}, { locale: this.$i18n.locale });
      this.description = this.$t('faangPlusApiSimplifier.description', {}, { locale: this.$i18n.locale });
      this.fonctionnalites.push(this.$t('faangPlusApiSimplifier.func1', {}, { locale: this.$i18n.locale }));
    },
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'icon':
          switch (this.theme) {
            case 'light': return 'iconColorLightTheme';
            default: return 'iconColorLightTheme'
          }
        case 'border':
          switch (this.theme) {
            case 'light': return 'projectBorderStylesLightTheme';
            default: return 'projectBorderStylesLightTheme'
          }
        case 'texte':
          switch (this.theme) {
            case 'light': return 'lightThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        default:
      }
    }
  },
}
</script>

<style scoped>
.tailleImageFAANGPlus {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tailleImageFAANGPlusMobile {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}
</style>