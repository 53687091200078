<template>
  <div>
    <transition-group
      name="fade"
      tag="div"
    >
      <div
        v-for="image in images"
        :key="image"
      >
        <img
          class="tailleImage"
          :src="currentImg"
          alt="image.png"
        >
      </div>
    </transition-group>
  </div>
</template>

<script>
// import Button from './Button.vue';

export default {
  name: 'SliderComponent',
  components: {
    // Button,
  },
  props: {
    imagesProp: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      timer: null,
      currentIndex: 0,
      compteur: 0,
      images: [],
    };
  },
  computed: {
    imageCourante() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
    currentImg() {
      const img = this.images[Math.abs(this.currentIndex) % this.images.length];
      return require('../../assets/img/'+ img)
    }
  },
  created() {
    this.images = this.imagesProp;
  },
  mounted() {
    this.correctionImages();
    this.startSlide();
  },
  methods: {
    prochaine() {
      this.currentIndex += 1;
    },
    precedente() {
      this.currentIndex -= 1;
    },
    correctionImages() {
      // Si une image est ajoutée dans home, le nombre dans cpt > X doit être augmenté
      var images = document.getElementsByTagName('img');
      const arrayLength = images.length;
      for (var cpt = 0; cpt < arrayLength; cpt++) {
        if (cpt > 2) {
            if (images[cpt] !== undefined) {
                images[cpt].remove();
                cpt -= 1;
            }
        }
      }
    },
    startSlide() {
      this.timer = setInterval(this.prochaine, 3500);
    },
  },
};
</script>

<style scoped>
.tailleImage {
  width: 40%;
  height: auto;
}

.tailleBtn {
  width: 80px;
  margin-right: 20px;
  margin-left: 20px;
}
</style>