<template>
  <div
    class="fullContent"
    :class="gestionThemes('border')"
  >
    <h1
      style="padding-top:15px"
      :class="gestionThemes('texte')"
    >
      <span>
        <a
          class="titre"
          style="margin-left:20px"
          :href="lienSiteWeb"
        >
          {{ mainTitle }}
          <i class="fa-solid fa-arrow-up-right-from-square iconRedirection" />
        </a>
        <h2
          v-if="currentLang != 'fr'"
          class="annees"
        >
          {{ translatedTitle }}
        </h2>
      </span>
    </h1>
    <h2
      class="annees"
      :class="gestionThemes('texte')"
    >
      {{ startDate }}-{{ endDate }}
    </h2>
    <h3
      class="headerFonctionnalites"
      :class="gestionThemes('texte')"
    >
      {{ $t("presentationComponent.tasks", {}, { locale: $i18n.locale }) }}
    </h3>
    <div class="features">
      <ul
        v-if="!estMobile"
        style="display:flex"
      >
        <div
          v-for="(item, index) in tasks"
          :key="index"
        >
          <li style="display:flex">
            <div class="divDot">
              <span class="dot" />
            </div>
            <p
              style="padding:5px"
              :class="gestionThemes('texte')"
            >
              {{ item }}
            </p>
          </li>
        </div>
      </ul>
      <ul v-else>
        <div
          v-for="(item, index) in tasks"
          :key="index"
        >
          <li style="padding:10px">
            <p :class="gestionThemes('texte')">
              {{ item }}
            </p>
          </li>
        </div>
      </ul>
    </div>
    <div style="padding-bottom: 15px;margin-left:20px;margin-right:20px;text-align:justify">
      <p :class="gestionThemes('texte')">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import UtilitiesLightTheme from '../../utilities/UtilitiesLightTheme';

export default {
  name: 'PresentationComponent',
  props: {
    mainTitle: {
        type: String,
        default: 'Todo - Titre projet'
    },
    translatedTitle: {
        type: String,
        default: 'Todo - Titre projet'
    },
    description: {
        type: String,
        default: 'Inclure bonne description'
    },
    tasks: [],
    startDate: {
        type: String,
        default: '20XX'
    },
    endDate: {
        type: String,
        default: '20XX'
    },
    lienSiteWeb: {
      type: String,
      default: 'Inclure lien'
    }
  },
  data() {
    return {
      theme: '',
      currentLang: '',
      borderType: '',
      texteType: '',
      estMobile: false,
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.currentLang = localStorage.getItem('currentLang');
    this.borderType = 'border';
    this.texteType = 'texte';
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'border':
          switch (this.theme) {
            case 'light': return 'projectBorderStylesLightTheme';
            default: return 'projectBorderStylesLightTheme'
          }
        case 'texte':
          switch (this.theme) {
            case 'light': return 'lightThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        default:
          // Thème invalide, donc l'alerte blanche est utilisée par défaut
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error',
            this.$t('errors.styleError', {}, { locale: this.$i18n.locale }), this.$t('errors.styleErrorDesc', {}, { locale: this.$i18n.locale }));
          break;
      }
    }
  }
};
</script>

<style scoped>
.annees {
  margin-top: 0;
  font-size: 20px;
  font-style: italic;
}

.titre {
  text-decoration: none;
  color:inherit;
}
</style>