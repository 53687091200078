<template>
  <div
    id="divNavbar"
    class="navbar"
    :class="stylesNavbarThemes()"
  >
    <a @click="changementHref('/')"><i class="fa fa-home" /></a>
    <!-- Dropdown projets personnels -->
    <div class="stylesDropdown">
      <button
        class="dropbtn"
        @click="dropdownPersonalProjects()"
      >
        <i class="fa fa-laptop-code" />
      </button>
      <div
        id="idDropdownPersonalProjects"
        class="stylesDropdownContent"
        :class="couleursDropdownBackground()"
      >
        <p>
          <u>{{ $t("sidebar.projects", {}, { locale: $i18n.locale }) }}</u>
        </p>
        <a href="#">{{
          $t("sidebar.mainProjects.portfolio", {}, { locale: $i18n.locale })
        }}<i class="fa fa-user espacementIcones" /></a>
        <a @click="changementHref('/PersonalProjects/OnlineGenerators')">{{ $t("sidebar.OG", {}, { locale: $i18n.locale })
        }}<i class="fa fa-lock espacementIcones" /></a>
        <a @click="changementHref('/PersonalProjects/FAANGPlusApiSimplifier')">{{ $t("sidebar.FAANGPlusApiSimplifier", {}, { locale: $i18n.locale })
        }}<i class="fa fa-book espacementIcones" /></a>
      </div>
    </div>

    <!-- Dropdown expérience travail -->
    <div class="stylesDropdown">
      <button
        class="dropbtn"
        @click="dropdownWorkExperience()"
      >
        <i class="fa fa-briefcase" />
      </button>
      <div
        id="idDropdownWorkExperience"
        class="stylesDropdownContent"
        style="min-width: 220px"
      >
        <p>
          <u>{{
            $t("sidebar.workExperience", {}, { locale: $i18n.locale })
          }}</u>
        </p>
        <a @click="changementHref('/WorkExperience/CSSP')">{{ $t("sidebar.cssp", {}, { locale: $i18n.locale })
        }}<i class="fa fa-school espacementIcones" /></a>
        <a @click="changementHref('/WorkExperience/Scores')">{{ $t("sidebar.scores", {}, { locale: $i18n.locale })
        }}<i class="fa fa-utensils espacementIcones" /></a>
      </div>
    </div>

    <!-- Dropdown parcours académique -->
    <div class="stylesDropdown">
      <button
        class="dropbtn"
        @click="dropdownAcademicBackground()"
      >
        <i class="fa fa-graduation-cap" />
      </button>
      <div
        id="idDropdownAcademicBackground"
        class="stylesDropdownContent"
        style="margin-right: 20px; width:150px"
      >
        <p>
          <u>{{
            $t("sidebar.academicBackground", {}, { locale: $i18n.locale })
          }}</u>
        </p>
        <a @click="changementHref('/AcademicBackground/Cegep')">{{ $t("sidebar.college", {}, { locale: $i18n.locale })
        }}<i class="fa fa-school-flag espacementIcones" /></a>
        <a @click="changementHref('/AcademicBackground/Highschool')">{{ $t("sidebar.highSchool", {}, { locale: $i18n.locale })
        }}<i class="fa fa-bus espacementIcones" /></a>
      </div>
    </div>

    <!-- Dropdown informations -->
    <div class="stylesDropdown">
      <button
        class="dropbtn"
        @click="dropdownUser()"
      >
        <i class="fa fa-user" />
      </button>
      <div
        id="idDropdownUser"
        class="stylesDropdownContent"
      >
        <p>
          <u>{{
            $t("sidebar.contact.header", {}, { locale: $i18n.locale })
          }}</u>
        </p>
        <a href="https://www.linkedin.com/in/anthony-boileau-7211b7225/">{{ $t("sidebar.contact.linkedIn", {}, { locale: $i18n.locale })
        }}<i class="fa-brands fa-linkedin espacementIcones" /></a>
        <a href="https://github.com/AnthonyBoileau">{{ $t("sidebar.contact.github", {}, { locale: $i18n.locale })
        }}<i class="fa-brands fa-github espacementIcones" /></a>
        <a href="mailto:anthonyboileau@hotmail.com">{{ $t("sidebar.contact.mail", {}, { locale: $i18n.locale })
        }}<i class="fa fa-envelope espacementIcones" /></a>
      </div>
    </div>

    <!-- Dropdown options -->
    <div class="stylesDropdown">
      <button
        class="dropbtn"
        @click="dropdownOptions()"
      >
        <i class="fa fa-gear" />
      </button>
      <!-- Langues -->
      <div
        id="idDropdownOptions"
        class="stylesDropdownContent"
        style="margin-left: -35px"
      >
        <div class="stylesDropdown">
          <button
            class="dropbtn"
            :class="backgroundColorSubIcons()"
            @click="dropdownLangues()"
          >
            <i class="fa fa-globe" />
          </button>
          <div
            id="idDropdownLangues"
            class="stylesDropdownContent"
            style="margin-left: -56px"
          >
            <a @click="changerLangue('fr')">Français</a>
            <a @click="changerLangue('en')">English</a>
          </div>
        </div>
        <!-- Thèmes -->
        <div class="stylesDropdown">
          <button
            class="dropbtn"
            :class="backgroundColorSubIcons()"
            @click="dropdownThemes()"
          >
            <i class="fa fa-brush" />
          </button>
          <div
            id="idDropdownThemes"
            class="stylesDropdownContent"
            style="min-width: 110px"
          >
            <a @click="changerThemes('light')">{{ $t("sidebar.themes.light", {}, { locale: $i18n.locale })
            }}<i class="fa fa-sun espacementIcones" /></a>
            <a @click="changerThemes('dark')">{{ $t("sidebar.themes.dark", {}, { locale: $i18n.locale })
            }}<i class="fa fa-moon espacementIcones" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu',
  data() {
    return {
      theme: '',
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
  },
  methods: {
    // Dropdowns
    dropdownPersonalProjects() {
      document
        .getElementById('idDropdownPersonalProjects')
        .classList.toggle('show');
    },
    dropdownWorkExperience() {
      document
        .getElementById('idDropdownWorkExperience')
        .classList.toggle('show');
    },
    dropdownAcademicBackground() {
      document
        .getElementById('idDropdownAcademicBackground')
        .classList.toggle('show');
    },
    dropdownUser() {
      document.getElementById('idDropdownUser').classList.toggle('show');
    },
    dropdownOptions() {
      document.getElementById('idDropdownOptions').classList.toggle('show');
    },
    dropdownLangues() {
      document.getElementById('idDropdownLangues').classList.toggle('show');
    },
    dropdownThemes() {
      document.getElementById('idDropdownThemes').classList.toggle('show');
    },
    cacherOptionsMenu() {
      // Variables
      const personalProjects = document.getElementById(
        'idDropdownPersonalProjects'
      );
      const workExperience = document.getElementById(
        'idDropdownWorkExperience'
      );
      const academicBackground = document.getElementById(
        'idDropdownAcademicBackground'
      );
      const user = document.getElementById('idDropdownUser');
      const options = document.getElementById('idDropdownOptions');
      const itemsMenu = [];
      itemsMenu.push(personalProjects);
      itemsMenu.push(workExperience);
      itemsMenu.push(academicBackground);
      itemsMenu.push(user);
      itemsMenu.push(options);

      // Vérifications d'un clic hors du menu
      itemsMenu.forEach((item) => {
        if (item.classList.contains('show')) {
          item.classList.remove('show');
        }
      });
    },
    // Logique
    changerLangue(langue) {
      this.$emit('changementLangue', langue);
      this.cacherOptionsMenu();
    },
    changerThemes(theme) {
      this.$emit('changementTheme', theme);
      this.cacherOptionsMenu();
    },
    changementHref(composant) {
      this.$emit('changementHref', composant);
      this.cacherOptionsMenu();
    },
    // Styles
    stylesNavbarThemes() {
      this.$nextTick(() => {
        const navbar = document.getElementById('divNavbar');
        const dropdownButtons = document.getElementsByClassName('dropbtn');
        switch (this.theme) {
          case 'light':
            navbar.classList.add('navbarLightTheme');
            this.setupFocusBoutons(
              dropdownButtons,
              '#8F8F99',
              '#A9A9AB',
              'btnLightTheme'
            );
            break;
          default:
            navbar.classList.add('navbarLightTheme');
            this.setupFocusBoutons(
              dropdownButtons,
              '#8F8F99',
              '#A9A9AB',
              'btnLightTheme'
            );
            break;
        }
      });
    },
    setupFocusBoutons(
      dropdownButtons,
      backgroundFocus,
      backgroundNormal,
      couleurBtns
    ) {
      for (let i = 0; i < dropdownButtons.length; i++) {
        dropdownButtons[i].addEventListener('focus', function () {
          this.style.setProperty('background-color', backgroundFocus);
        });
        dropdownButtons[i].addEventListener('mouseout', function () {
          this.style.setProperty('background-color', backgroundNormal);
        });
        dropdownButtons[i].classList.add(couleurBtns);
      }
    },
    backgroundColorSubIcons() {
      switch(this.theme) {
        case 'light':
          return 'subIconLightTheme';
        default:
          return 'subIconLightTheme';
      }
    },
    couleursDropdownBackground() {
      this.$nextTick(() => {
        // Variables
        const personalProjects = document.getElementById(
          'idDropdownPersonalProjects'
        );
        const workExperience = document.getElementById(
          'idDropdownWorkExperience'
        );
        const academicBackground = document.getElementById(
          'idDropdownAcademicBackground'
        );
        const user = document.getElementById('idDropdownUser');
        const options = document.getElementById('idDropdownOptions');
        const langues = document.getElementById('idDropdownLangues');
        const themes = document.getElementById('idDropdownThemes');
        const itemsMenu = [];
        itemsMenu.push(personalProjects);
        itemsMenu.push(workExperience);
        itemsMenu.push(academicBackground);
        itemsMenu.push(user);
        itemsMenu.push(options);
        itemsMenu.push(langues);
        itemsMenu.push(themes);

        itemsMenu.forEach((item) => {
          switch (this.theme) {
            case 'light':
              item.classList.add('dropdownLightTheme');
              break;
            default:
              item.classList.add('dropdownLightTheme');
              break;
          }
        });
      })
    },
  },
};
</script>

<style scoped>
/* ---- Navbar -------- */
.navbar {
  overflow: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar a {
  float: left;
  font-size: 16px;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbarLightTheme {
  background-color: #a9a9ab;
}

.subIconLightTheme {
  background-color: #8f8f93 !important;
}

.navbarLightTheme a,
.btnLightTheme {
  color: white;
}
/* -------------------- */

.stylesDropdown {
  float: left;
  overflow: hidden;
}

.transparence {
  opacity: 0.5;
}

.stylesDropdown .dropbtn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 15px 12px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.stylesDropdownContent {
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.stylesDropdownContent a {
  float: none;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdownLightTheme {
  background-color: #dddddd;
}

.dropdownLightTheme a, .dropdownLightTheme p {
  color: black;
}

.stylesDropdownContent a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.espacementIcones {
  padding-left: 7px;
}
</style>