<template>
  <div>
    <Presentation
      :mainTitle="mainTitle"
      :translatedTitle="translatedTitle"
      :description="description"
      :startDate="'2022'"
      :endDate="''"
      :tasks="tasks"
      :lienSiteWeb="'https://cssp.gouv.qc.ca/'"
    />
    <img
      src="../../../assets/img/CSSP/CSSP.png"
      alt="CSSP.png"
      :class="estMobile == false ? 'tailleImageCSSP' : 'tailleImageCSSPMobile'"
    >
  </div>
</template>

<script>
import Presentation from '../../composantsGlobal/Presentation.vue';

export default {
  name: 'CSSP',
  components: {
    Presentation
  },
  data() {
    return {
      mainTitle: '',
      description: '',
      tasks: [],
      translatedTitle: '',
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.gestionInternationalisation();
  },
  methods: {
    gestionInternationalisation() {
      this.mainTitle = this.$t('cssp.mainTitle', {}, { locale: this.$i18n.locale });
      this.translatedTitle = this.$t('cssp.translatedTitle', {}, { locale: this.$i18n.locale });
      this.description = this.$t('cssp.description', {}, { locale: this.$i18n.locale });
      this.tasks.push(this.$t('cssp.frontendTasks', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('cssp.backendTasks', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('cssp.other', {}, { locale: this.$i18n.locale }));
      this.tasks.push(this.$t('cssp.barely', {}, { locale: this.$i18n.locale }));
    },
  },
}
</script>

<style scoped>
.tailleImageCSSP {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tailleImageCSSPMobile {
  width: 75%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.tailleImageCSSP, .tailleImageCSSPMobile {
  border: 1px solid black;
}
</style>