<template>
  <CSSP />
</template>

<script>
import CSSP from '../../components/workExperience/Scores.vue';

export default {
  name: 'ScoresView',
  components: {
    CSSP
  }
}
</script>

<style scoped>

</style>