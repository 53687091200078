<template>
  <HighSchool />
</template>

<script>
import HighSchool from '../../components/school/highSchool/HighSchool.vue';

export default {
  name: 'HighSchoolView',
  components: {
    HighSchool
  }
}
</script>

<style scoped>

</style>