<template>
  <div
    class="fullContent"
    :class="gestionThemes(borderType)"
  >
    <h1
      style="padding-top:15px"
      :class="gestionThemes(texteType)"
    >
      <span>
        <a
          class="titre"
          style="margin-left:20px;line-break: anywhere;"
          :href="lienProjetOuExe"
        >
          {{ titre }}
          <i class="fa-solid fa-arrow-up-right-from-square iconRedirection" />
        </a>
      </span>
    </h1>
    <h3
      class="headerFonctionnalites"
      :class="gestionThemes(texteType)"
    >
      {{ $t("projectComponent.interestingFeatures", {}, { locale: $i18n.locale }) }}
    </h3>
    <div class="features">
      <ul
        v-if="!estMobile"
        style="display:flex"
      >
        <div
          v-for="(item, index) in fonctionnalites"
          :key="index"
        >
          <li style="display:flex;padding-left:40px">
            <div class="divDot">
              <span class="dot" />
            </div>
            <p :class="gestionThemes(texteType)">
              {{ item }}
            </p>
          </li>
        </div>
      </ul>
      <ul v-else>
        <div
          v-for="(item, index) in fonctionnalites"
          :key="index"
        >
          <li style="padding:10px">
            <p :class="gestionThemes(texteType)">
              {{ item }}
            </p>
          </li>
        </div>
      </ul>
    </div>
    <div style="padding-bottom: 15px;margin-left:20px;margin-right:20px;text-align:justify">
      <p :class="gestionThemes(texteType)">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import UtilitiesLightTheme from '../../utilities/UtilitiesLightTheme';

export default {
  name: 'ProjetComponent',
  props: {
    titre: {
        type: String,
        default: 'Todo - Titre projet'
    },
    description: {
        type: String,
        default: 'Inclure bonne description'
    },
    lienProjetOuExe: {
        type: String,
        default: 'Inclure lien'
    },
    texteLien: {
        type: String,
        default: 'Inclure lien'
    },
    fonctionnalites: []
  },
  data() {
    return {
      theme: '',
      borderType: '',
      texteType: '',
      estMobile: false,
    };
  },
  created() {
    this.theme = localStorage.getItem('currentTheme');
    this.borderType = 'border';
    this.texteType = 'texte';
  },
  mounted() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      this.estMobile = true;
    }
  },
  methods: {
    gestionThemes(typeBalise) {
      switch (typeBalise) {
        case 'border':
          switch (this.theme) {
            case 'light': return 'projectBorderStylesLightTheme';
            default: return 'projectBorderStylesLightTheme'
          }
        case 'texte':
          switch (this.theme) {
            case 'light': return 'lightThemeTextColor';
            default: return 'lightThemeTextColor'
          }
        default:
          // Thème invalide, donc l'alerte blanche est utilisée par défaut
          UtilitiesLightTheme.CreerAlerteSwalNoQuestion(5000, 'error',
            this.$t('errors.styleError', {}, { locale: this.$i18n.locale }), this.$t('errors.styleErrorDesc', {}, { locale: this.$i18n.locale }));
          break;
      }
    }
  }
}
</script>

<style scoped>
.features {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 15px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #111111;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.divDot {
  margin-top:13px;
  margin-right: 5px;
}

.headerFonctionnalites {
  margin-top: 10px;
  margin-bottom: -15px;
}

.titre {
  text-decoration: none;
  color:inherit;
}
</style>